import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import copy from "copy-to-clipboard";
import {FormattedMessage, useIntl} from "react-intl";
import Panel from "./panel";
import CopyImage from "../../../static/content/font-awesome/light/copy.svg";

import {getFormData} from "../../components/manager/form";

export default function FinalSuccess(props) {
  const {formatMessage} = useIntl();
  const data = getFormData();
  const {response} = data;
  // function handleLoginNavigate() {
  //   clearFormData();
  //   return history.push("/login");
  // }

  function handleCopyPassword() {
    const data = getFormData();
    const {response} = data;
    return copy(response.data.newPassword, {
      debug: true,
      message: "Press #{key} to copy",
    });
  }

  // function handleCopyUserName() {
  //   const data = getFormData();
  //   const {response} = data;
  //   return copy(response.data.userName, {
  //     debug: true,
  //     message: "Press #{ key} to copy",
  //   });
  // }
  return (
    <Panel className="final-success">
      <div className="result-screen">
        <Container>
          <Row className="justify-content-center">
            <Col className="mx-auto" xs={12} sm={6}>
              <div className="inner">
                <Container fluid>
                  <Row>
                    <Col xs={12} className="title">
                      <FormattedMessage id={"register.final.congratulations"} />
                    </Col>
                    <Col xs={12} className="header">
                      <h2><FormattedMessage id={"register.final.success-message"} /></h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="subtitle">
                      <FormattedMessage id={"register.final.success-message.subtitle"} /><br/>
                      <FormattedMessage id={"register.final.subtext"} />
                    </Col>
                  </Row>
                  <Row>
                    <div className="creds">
                      <div className="username">
                        <span className="vn-label">
                          <FormattedMessage id={"register.final.order-id"} />
                        </span>
                        <span className="vn-value">
                          {String2Hex(response.data.id)}
                        </span>
                      </div>
                      <div className="username">
                        <span className="vn-label">
                          <FormattedMessage id={"register.final.username"} />
                        </span>
                        <span className="vn-value">
                          {response.data.userName}
                        </span>
                      </div>
                      <div className="password">
                        <span className="vn-label">
                          <FormattedMessage id={"register.final.password"} />
                        </span>
                        <span className="vn-value">
                          {response.data.newPassword}
                        </span>
                        <span className="vn-copy">
                          <div
                            className="vw-copyicon"
                            style={{backgroundImage: `url(${CopyImage})`}}
                            onClick={handleCopyPassword}
                          />
                        </span>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <Col xs={12} className="buttons">
                      <a href="/portal" className="vw-button orange" style={{padding: "10px 30px"}}>
                        {formatMessage({id: "buttons.login"})}
                      </a>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Panel>
  );
}

function String2Hex(tmp) {
  var str = "";
  for (var i = 0; i < tmp.length; i++) {
    str += tmp[i].charCodeAt(0).toString(16);
  }
  return str;
}
