import React from "react";
import {withFormsy} from "formsy-react";

class SelectField extends React.Component {
  componentWillMount() {
    if (this.props.defaultValue) {
      return this.props.setValue(this.props.defaultValue);
    }
    return undefined;
  }
  focus() {
    return this.input.focus();
  }
  render() {
    const className = this.props.showRequired ? "required" : this.props.showError ? "error" : null;
    let errorMessage;
    if (this.props.showError) {
      errorMessage = this.props.errorMessage;
    }
    return (<div className={`input ${className} ${(!this.props.className) ? "" : this.props.className}`} ref={(r) => {this.divInput = r;}}>
      <div className="inner-input" ref={(r)=>{this.divInnerInput = r;}}>
        {this.props.beforeInput}
        <select
          ref={(i) => {this.input = i;}}
          disabled={this.props.disabled}
          className={this.props.inputClassName}
          style={this.props.inputStyle}
          formNoValidate
          value={this.props.value || ""}
          onChange={(e) => {
            console.log("data?", e.target.value);
            if(this.props.getValue() !== e.target.value) {
              this.props.setValue(e.target.value);
              if (this.props.onChange) {
                return this.props.onChange(e);
              }
            }
          }}
          onKeyDown={(e) => {
            if (this.props.onPressEnter && e.keyCode === 13) {
              return this.props.onPressEnter(e);
            }
            return undefined;
          }}>
            {this.props.children}
        </select>
      </div>
      <div className="input-message">
        {errorMessage}
      </div>

    </div>);
  }
}


export default withFormsy(SelectField);
