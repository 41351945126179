import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../../components/bootstrap/text-field";
import Form from "formsy-react";
import {getFormData, setFormData} from "../../../components/manager/form";


export default function UserDetailsCardNumber() {

  const history = useHistory();
  const {formatMessage} = useIntl();
  function handleOnSubmit(data) {
    setFormData(data);
    return history.push("/user/email");
  }
  const data = getFormData();
  return (
    <Form className="section" onValidSubmit={handleOnSubmit}>
      <div>
        <Container>
          <Row>
            <Col xs={12}>
              <div className="section-title">
                <FormattedMessage id="register.userDetails.name.title" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6} xs={12}>
              <TextField
                name="firstName"
                className="vw-form-field input-radius"
                required
                placeholder={formatMessage({id: "placeholders.firstName"})}
                validations={{
                  maxLength: 50,
                  isExisty: true,
                  isValidName: ({firstName},) => {
                    const regex = new RegExp("^[a-zA-Z ]*$", "g");
                    return regex.test(firstName);
                  }
                }}
                validationErrors={{
                  isExisty: (<FormattedMessage id="validation.errors.required.firstName" />),
                  maxLength: (<FormattedMessage id="validation.errors.maxLength50" />),
                  isValidName: (<FormattedMessage id="validation.errors.isAlpha" />),
                }}
                value={data.firstName}
              />
            </Col>
            <Col sm={6} xs={12}>
              <TextField
                name="lastName"
                className="vw-form-field input-radius"
                placeholder={formatMessage({id: "placeholders.lastName"})}
                value={data.lastName}
                required
                validations={{
                  maxLength: 50,
                  isExisty: true,
                  isValidName: ({lastName}) => {
                    if (lastName !== undefined) {
                      if (lastName.trim().length) {
                        const regex = new RegExp("^[a-zA-Z']*$", "g");
                        return regex.test(lastName.trim());
                      }
                    }

                    return false;
                  },
                }}
                validationErrors={{
                  isExisty: (<FormattedMessage id="validation.errors.required.lastName" />),
                  maxLength: (<FormattedMessage id="validation.errors.maxLength50" />),
                  isValidName: (<FormattedMessage id="validation.errors.isAlpha" />),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="input-row">
                <div className="submit">
                  <span>
                    <FormattedMessage id="register.userDetails.keyPressEnter" />
                  </span>
                  <input
                    className="vw-button orange next-btn"
                    type="submit"
                    value={formatMessage({id: "register.userDetails.buttonConfirm"})}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Form>
  );
}
