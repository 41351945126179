import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../../components/bootstrap/text-field";
import Captcha from "../../../components/bootstrap/captcha";
import Form from "formsy-react";
import { getFormData, setFormData } from "../../../components/manager/form";
import { createMobileVerificationMutation } from "../../../components/logic/mobile";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import {useAsyncSetState} from "use-async-setstate";

export default function UserDetailsPhone(props) {
  const [error, setError] = useAsyncSetState("");
  const [createMobileVerification] = useMutation(
    createMobileVerificationMutation
  );
  const history = useHistory();
  const { formatMessage } = useIntl();

  async function handleOnSubmit(data) {
    try {
      setFormData(data);

      const response = await createMobileVerification({
        variables: {
          mobileNumber: data.mobileNumber,
          captcha: data.captcha,
          captchaKey: data.captchaKey,
        },
      });

      if (
        response?.data?.classMethods?.MobileVerification
          ?.createMobileVerification
      ) {
        return history.push("/user/verification");
      }
    } catch (err) {
      await setError(err?.message);
    }
  }
  const data = getFormData();
  let beforeInput;

  return (
    <Form className="section" onValidSubmit={handleOnSubmit}>
      <div>
        <Container>
          <Row style={{ marginBottom: "1em" }}>
            <Col xs={12} className="section-title">
              <FormattedMessage id="register.userDetails.mobileNumber.title" />
            </Col>
          </Row>
          {error && <Row>
            <Col>
              <div className="alert alert-danger my-1">
                {error}
              </div>
            </Col>
          </Row>}
          <Row>
            <Col md={6} xs={12} className="vw-mobile">
              <TextField
                className="vw-form-field input-radius phone-input ml-0"
                beforeInput={beforeInput}
                pattern="[0-9]*"
                inputMode="numeric"
                inputClass="form-control ml-0"
                autoComplete="off"
                required
                placeholder={formatMessage({ id: "placeholders.mobileNumber" })}
                validations={{
                  maxLength: 50,
                  isNumeric: true,
                  isExisty: true,
                }}
                validationErrors={{
                  isExisty: (
                    <FormattedMessage id="validation.errors.required.mobileNumber" />
                  ),
                  maxLength: (
                    <FormattedMessage id="validation.errors.maxLength50" />
                  ),
                  isNumeric: (
                    <FormattedMessage id="validation.errors.isNumeric" />
                  ),
                }}
                name="mobileNumber"
                value={data.mobileNumber}
                onPressEnter={(e) => {
                  // e.preventDefault()
                  // captcha.focus();
                  // return false;
                }}
              />
            </Col>
            <Col md={6} xs={12} className="vw-mobile">
              {/* <Container fluid> */}
              <Captcha
                inputClassName="vw-form-field input-radius phone-input"
                rowClass="vw-form-field"
              />
              {/* </Container> */}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className={"submit single"}>
                <span>
                  <FormattedMessage id={"register.userDetails.keyPressEnter"} />
                </span>
                <input
                  type="submit"
                  className="vw-button orange next-btn"
                  value={formatMessage({
                    id: "register.userDetails.buttonConfirm",
                  })}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Form>
  );
}
