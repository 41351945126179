import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TextField from "../../../components/bootstrap/text-field";
import Form from "formsy-react";

import { getFormData, setFormData } from "../../../components/manager/form";


export default function UserDetailsEmail() {

  const history = useHistory();
  const { formatMessage } = useIntl();

  async function handleOnSubmit(data) {
    setFormData(data);
    return history.push("/user/address");
  }
  const data = getFormData();
  return (
    <Form className="section" onValidSubmit={handleOnSubmit}>
      <div>
        <Container>
          <Row>
            <Col xs={12}>
              <div className="section-title" style={{ textAlign: "center" }}>
                <FormattedMessage id="register.userDetails.email.title" values={data} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mx-auto" sm={6} xs={12}>
              <TextField name="email"
                className="vw-form-field input-radius input-email"
                required
                placeholder={formatMessage({ id: "placeholders.email" })}
                validations={{
                  maxLength: 50,
                  isEmail: true,
                  isExisty: true,
                }}
                validationErrors={{
                  isExisty: (<FormattedMessage id="validation.errors.required.email" />),
                  maxLength: (<FormattedMessage id="validation.errors.maxLength50" />),
                  isEmail: (<FormattedMessage id="validation.errors.isEmail" />),
                }}
                value={data.email} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="input-row">
                <div className="submit">
                  <span><FormattedMessage id="register.userDetails.keyPressEnter" /></span>
                  <input type="submit" className="vw-button orange next-btn" value={formatMessage({ id: "register.userDetails.buttonConfirm" })} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Form>);
}
