import React, {useState, Fragment} from "react";
import {Link} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {useQuery} from "@apollo/client";
import {Container, Col, Row} from "react-bootstrap";

import {getFormData, setFormData} from "../../components/manager/form";
import Panel from "./panel";
import {getProductsQuery, getProductsResult} from "../../components/logic/products";
import {getPlanSpeedLabel, getPlanImage} from "./plan";
import AddonsDisplay from "./addons-display";
import {getBuildingCIS} from "../../components/logic/address";
import {CIS_LINK, NON_INTERNET_PRODUCT_TYPES_TO_SHOW} from "../../utils/constants";

const Index = (props) => {
  const {buildingCode} = getFormData();
  const query = useQuery(getBuildingCIS, {
    variables: {
      code: buildingCode,
    },
  });

  if (query.loading) {
    return <Fragment />;
  }

  const cisLink = query?.data?.classMethods?.Building?.getBuildingCIS || CIS_LINK;

  return (
    <Review cisLink={cisLink} {...props} />
  );
};

const Review = ({cisLink}) => {
  const {formatMessage} = useIntl();
  const [date, setDate] = useState();
  const data = getFormData();

  const {selectedProduct, voucherCode, addons = []} = data;
  const result = useQuery(getProductsQuery, {
    variables: {
      buildingCode: data.buildingCode,
      voucherCode: data.voucherCode,
      showHidden: true,
      selectedProductIds: [],
    },
  });

  if (result.loading) {
    return <React.Fragment />;
  }

  const products = getProductsResult(result);

  if (!data.selectedProduct) {
    return <div />;
  }

  const {period} = selectedProduct;

  const setupProducts = products.filter(
    (pp) => pp.required && pp.id !== selectedProduct.id && !pp.onlyNewConnection
  );

  let newConnectionProducts = [];
  if (data.address.isNewConnection) {
    newConnectionProducts = products.filter(
      (pp) => pp.id !== selectedProduct.id && pp.onlyNewConnection
    );
  }

  let connectionFee = newConnectionProducts.reduce(
    (c, ncp) => c + parseFloat(ncp.value),
    0
  );
  let setupFee = setupProducts.reduce((c, ncp) => c + parseFloat(ncp.value), 0);
  const total = Number(selectedProduct.value +
    connectionFee +
    setupFee +
    addons.reduce((o, p) => p.value + o, 0)
  ).toFixed(2);

  const perMonth =
    selectedProduct.value +
    addons
      .filter((p) => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.indexOf(p.type) > -1)
      .reduce((o, p) => p.value + o, 0);

  const handleOnAddProduct = (product, selectEventProducts) => {
    const newAddons = [...addons];

    if (!addons.find(ao => ao.id === product.id)) { // Makes sure no duplicate
      newAddons.push(product);
    }

    selectEventProducts.map(sep => {
      if (!addons.find(ao => ao.id === sep.id)) { // Makes sure no duplicate
        newAddons.push(sep);
      }
    });

    const hardware = newAddons.filter((p) => p.type === "hardware").length > 0;
    setFormData({addons: newAddons, hardware});
    setDate(new Date()); //force page rerender
  };

  const handleOnRemoveProduct = (product, selectEvents) => {
    const newAddons = addons.filter((a) => a.id !== product.id);
    const hardware = newAddons.filter((p) => p.type === "hardware").length > 0;
    setFormData({addons: newAddons, hardware});
    setDate(new Date()); //force page rerender
  };

  return (
    <Panel className="review">
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className="title">
              <FormattedMessage id="register.review.title" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={10} className="mx-auto">
            <div className="product-options-row">
              <div className="option-1">
                <div className="inner">
                  <div>
                    {getPlanImage(selectedProduct.description)}
                  </div>
                  <div className="plan-details">
                    <div className="plan-rate">{selectedProduct.description}</div>
                    <div className="plan-at">{"at"}</div>
                    <div className="plan-value" style={{
                      textDecoration: (selectedProduct.originalValue) ? "line-through" : undefined
                    }}>{`$${selectedProduct.originalValue ? selectedProduct.originalValue : selectedProduct.value}/month`}</div>
                    {(selectedProduct || {}).voucherName && <div className="product-voucher">
                      {`$${selectedProduct.value} ${selectedProduct.voucherName}`}
                    </div>}
                  </div>

                  {connectionFee > 0 && (<React.Fragment>
                    <div className="text-center" style={{fontSize: 33, margin: 0, padding: 0}}>{"+"}</div>
                    <div className="text-center" style={{fontSize: 33, marginBottom: 10}}>
                      {`$${connectionFee} New Developments Charge`}
                    </div>
                    <div className="plan-label">
                      <div>{"(Once off)"}</div>
                    </div>
                  </React.Fragment>)}
                  {setupFee > 0 && (<React.Fragment>
                    <div className="text-center" style={{fontSize: 33, margin: 0, padding: 0}}>{"+"}</div>
                    <div className="text-center" style={{fontSize: 33, marginBottom: 10}}>
                      {`$${setupFee} Setup Fee`}
                    </div>
                    <div className="plan-label">
                      <div>{"(Once off)"}</div>
                    </div>
                  </React.Fragment>)}
                  <div className="plan-speed">
                    <div>{"Typical Evening Speed:"}</div>
                    <div>{`${getPlanSpeedLabel(selectedProduct.description)} Mbps`}</div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="addons-row">
          <Col xs={12} lg={10} className="mx-auto">
            <AddonsDisplay
              selectedProductIds={
                selectedProduct?.id ? [selectedProduct?.id] : []
              }
              voucherCode={voucherCode}
              onAddProduct={handleOnAddProduct}
              onRemoveProduct={handleOnRemoveProduct}
              addons={addons}
            />
          </Col>
        </Row>
      </Container>

      <Container className="review-footer">
        <Row>
          <Col xs={12}>
            <div className="buttons">
              <Link className="vw-button orange confirm-btn" to="/user/name">
                <FormattedMessage id={"buttons.confirm1"} />
              </Link>
            </div>
          </Col>
        </Row>
        {selectedProduct.voucherUses > 0 ? (
          <Row>
            <Col xs={12}>
              <div className="change">
                <div>
                  {`Voucher applies for ${selectedProduct.voucherUses} ${period
                    .split(" ")[1]
                    .toLowerCase()}`}
                </div>
              </div>
            </Col>
          </Row>
        ) : undefined}
        <Row>
          <Col xs={12}>
            <div className="change">
              <div>
                <Link to={"/plan"}>
                  <FormattedMessage id="register.review.back" />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="minimum">
              <FormattedMessage
                id="register.review.total"
                values={{
                  value: `${total}`,
                  renewal: formatMessage({
                    id: `register.products.renewal.${period
                      .split(" ")[1]
                      .toLowerCase()}`,
                  }),
                }}
              />
            </div>
            <div className="info-summary">
              <a
                // eslint-disable-next-line no-script-url
                href={cisLink || "javascript:void(0);"}
                target={cisLink ? "_blank" : ""}
              >
                <FormattedMessage id="register.review.critical" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Panel>
  );
};

export default Index;
