import React from "react";
import {withFormsy} from "formsy-react";

class TextField extends React.Component {
  componentWillMount() {
    if (this.props.value) {
      return this.props.setValue(this.props.value);
    }
    return undefined;
  }
  focus = () => {
    return this.input.focus();
  }
  render() {
    const fieldClassName = this.props.showRequired ? "required" : this.props.showError ? "error" : null;
    let errorMessage;
    if (this.props.showError) {
      errorMessage = this.props.errorMessage;
    }
    const {
      onPressEnter,
      onChange,
      setValue,
      getValue,
      inputStyle,
      type,
      textarea,
      beforeInput,
      className,
      validationError,
      validationErrors,
      isValidValue,
      showError,
      showRequired,
      isRequired,
      isFormSubmitted,
      isPristine,
      isValid,
      isFormDisabled,
      getErrorMessages,
      getErrorMessage,
      hasValue,
      resetValue,
      setValidations,
      placeholder,
      validations,
      innerRef,
      fullWidth,
      ...rest} = this.props;

    return (<div className={`input ${fieldClassName} ${(!this.props.className) ? "" : this.props.className}`} ref={(r) => {this.divInput = r}}>
      <div className="inner-input" ref={(r) => {this.divInnerInput = r}}>
        {this.props.beforeInput}
        {placeholder ? <div className={`vw-placeholder ${this.props.value ? "hasValue" : ""}`}>{}</div> : undefined}
        {this.props.textarea ? (<textarea 
          disabled={this.props.disabled}
          ref={(i) => {this.input = i;}}
          style={this.props.inputStyle}
          formNoValidate
          value={this.props.value || ""}
          onChange={(e) => {
            if(this.props.value !== e.target.value) {
              this.props.setValue(e.target.value);
              if (this.props.onChange) {
                this.props.onChange(e);
              }
            }
          }}
          onKeyDown={(e) => {
            if (this.props.onPressEnter && e.keyCode === 13) {
              return this.props.onPressEnter(e);
            }
            return undefined;
          }}
          {...rest} />) 
        : (<input type={type || "text"}
          disabled={this.props.disabled}
          ref={(i) => {this.input = i;}}
          style={this.props.inputStyle}
          {...rest}
          className={className}
          formNoValidate
          autoComplete="no"
          placeholder={this.props.placeholder}
          value={this.props.value || ""}
          onChange={(e) => {
            if(this.props.value !== e.target.value) {
              this.props.setValue(e.target.value);
              if (this.props.onChange) {
                this.props.onChange(e);
              }
            }
          }}
          onKeyDown={(e) => {
            if (this.props.onPressEnter && e.keyCode === 13) {
              return this.props.onPressEnter(e);
            }
            return undefined;
          }} />)}


      </div>
      <div className="input-message">
        {errorMessage}
      </div>

    </div>);
  }
}


export default withFormsy(TextField);
