import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Route} from "react-router-dom";

import {FormattedMessage, injectIntl} from "react-intl";
import Panel from "../panel";

import UserDetailsName from "./name";
import UserDetailsEmail from "./email";
import UserDetailsPhone from "./phone";
import UserDetailsVerification from "./verification";

import UserAddress from "./address";
import UserShippingAddress from "./shipping-address";
import UserCard from "./card-details";

function UserDetails(props) {
  return (<Panel className="userdetails" >
    <Container fluid>
      <Row>
        <Col>
          <div className="title" style={{textAlign: "center" }}>
            <FormattedMessage id="register.details.title" />
          </div>
        </Col>
      </Row>
      <Route exact path="/user/name" component={UserDetailsName} />
      <Route exact path="/user/email" component={UserDetailsEmail} />
      <Route exact path="/user/address" component={UserAddress} />
      <Route exact path="/user/shipping-address" component={UserShippingAddress} />
      <Route exact path="/user/phone" component={UserDetailsPhone} />
      <Route exact path="/user/verification" component={UserDetailsVerification} />
      <Route exact path="/user/card" component={UserCard} />
    </Container>
  </Panel>);
}

export default injectIntl(UserDetails);
