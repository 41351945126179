import moment from "moment";

const DataHelper = {
  cleanMobile(mobileNumber) {
    let mobileno;
    if (mobileNumber[0] === "0") {
      mobileno = mobileNumber.substring(1);
    } else {
      mobileno = mobileNumber;
    }

    return mobileno;
  },
  processDate(value) {
    if (!value) {
      return "";
    }
    return moment(value, "YYYY-MM-DD HH:mm:ssZ").format("DD MMM YYYY");
  },
  contactRequestTypes: [
    "Support Enquiry", 
    "Building Owners and Managers",
    "Corporate Enquiry",
    // "Other"
  ],
  ownersRequestTypes: [
    "Student Accommodation",
    "Residential New Property",
    "Residential Existing Property",
  ],
  supportRequestTypes: {
    "account": "Accounts and Billing",
    "signup": "Sign up Process",
    "wired": "No connectivity on Wired",
    "wireless": "No connectivity on Wireless",
    "low signal": "Low signal on Wireless",
    "slow": "Slow speed",
  },
  importTypes: [
    {text: "Vostronet", value: "csv"},
    {text: "Starrez", value: "strz"},
    {text: "Hirum", value: "hrm"},
  ],
  getUserStatusStyle(status) {
    switch(status) {
      case "Pending": return "#338ecc";
      case "Enabled": return "#49AD58";
      default: return "#ee4036";
    }
  },
  getCouponStatusStyle(status) {
    switch(status) {
      case "Expired": return "#ee4036";
      case "Pending": return "#338ecc";
      case "Active": return "#49AD58";
      default: return "#000";
    }
  },
  getURLQueryParams(url) {
    const query = (url || "").replace("?", "").split("&");
    return query.reduce((obj, p) => {
      const keys = p.split("=");
      return Object.assign({}, {
        [keys[0]]: keys[1],
      });
    }, {});
  },
  getDeviceIcon(vendor) {
    if (vendor === "No vendor found" || vendor === null) {
      return "fal fa-desktop";
    } else {
      const v = vendor.toLowerCase();
      switch (v) {
        case "apple":
          return "fab fa-apple";
        case "amazon":
          return "fab fa-amazon";
        case "blackberry":
          return "fab fa-blackberry";
        case "digital ocean":
          return "fab fa-digital-ocean"
        case "google":
          return "fab fa-google";
        case "microsoft":
          return "fab fa-microsoft";
        default:
          return "fal fa-desktop";
      }
    }
  }
};

export default DataHelper;