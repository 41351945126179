import React from "react";
import {FormattedMessage, injectIntl, useIntl} from "react-intl";
// import Component from "components/utils/promise";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getFormData} from "../../components/manager/form";
import Panel from "./panel";
import {Link} from "react-router-dom";
import ErrorMessage from "../../components/utils/error-message";

function FinalFailure (props) {
  const {formatMessage} = useIntl();
  const data = getFormData();
  const {response} = data;

  console.log("RESPONSE >>> ", response);

  return (
    <Panel className="final-error">
      <div className="result-screen">
        <Container>
          <Row>
            <Col xs={12} sm={6} className="mx-auto">
              <div className="inner">
                <Container fluid>
                  <Row>
                    <Col xs={12} className="header">
                      <h2>
                        <FormattedMessage id={"register.final.failure-message"} />
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="subtitle">
                      <h3>
                        {(() => {
                          if (response?.graphQLErrors) {
                            if (response.graphQLErrors.length > 0 && Array.isArray(response?.graphQLErrors)) {
                              return response.graphQLErrors.map((x) => {
                                if (typeof x === "string") {
                                  return ErrorMessage(x);
                                }
                                x = {
                                  message: x.message.includes("proper_email") ? <FormattedMessage id={"portal.details.invalidEmail"} /> : x.message,
                                };
                                return ErrorMessage(x.message);
                              });
                            }
                            return (<FormattedMessage id={"register.final.failure-message.error"} values={{message: response.graphQLErrors[0].message.slice(24, 59).split("\"")[1]}} />);
                          }
                          if (response?.message && typeof response?.message === "string") {
                            return response?.message;
                          }
                          return (<FormattedMessage id={"register.final.failure-message.error"} values={response} />);
                        })()}
                      </h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="buttons">
                      <Link to="/final" className="vw-button orange">
                        {formatMessage({id: "buttons.failure"})}
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Panel>
  );
}

export default injectIntl(FinalFailure);
